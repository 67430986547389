var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "content"
  }, [_c('PageHeader', {
    attrs: {
      "title": "Add an account"
    }
  }), _c('div', {
    staticClass: "block block block-rounded"
  }, [_c('ul', {
    staticClass: "nav nav-tabs nav-tabs-block nav-justified",
    attrs: {
      "role": "tablist"
    }
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('a', {
    staticClass: "nav-link",
    class: {
      active: _vm.step === 1,
      disabled: _vm.step === 3
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.onClickPrevStep
    }
  }, [_vm._v("1. Asset")])]), _c('li', {
    staticClass: "nav-item"
  }, [_c('a', {
    staticClass: "nav-link",
    class: {
      active: _vm.step === 2,
      disabled: _vm.step === 3
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.onClickNextStep
    }
  }, [_vm._v("2. Details")])]), _c('li', {
    staticClass: "nav-item"
  }, [_c('a', {
    staticClass: "nav-link",
    class: {
      disabled: _vm.step !== 2
    }
  }, [_vm._v("3. Confirmation")])])]), _c('div', [_c('div', {
    staticClass: "block-content block-content-sm"
  }, [_c('div', {
    staticClass: "progress",
    staticStyle: {
      "height": "8px"
    },
    attrs: {
      "data-wizard": "progress"
    }
  }, [_c('div', {
    staticClass: "progress-bar progress-bar-striped progress-bar-animated bg-primary",
    style: `width: ${_vm.step * 100 / _vm.totalSteps}%;`,
    attrs: {
      "role": "progressbar",
      "aria-valuenow": "30",
      "aria-valuemin": "0",
      "aria-valuemax": "100"
    }
  })])]), _c('div', {
    staticClass: "block-content block-content-full tab-content",
    staticStyle: {
      "min-height": "300px"
    }
  }, [_c('div', {
    staticClass: "tab-pane",
    class: {
      active: _vm.step === 1
    },
    attrs: {
      "data-cy": "accounts-form",
      "role": "tabpanel"
    }
  }, [_c('SectionTitle', [_vm._v("Select an asset")]), _c('div', {
    staticClass: "row push"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-lg-8 col-xl-4"
  }, [_vm.assetLoadingAction.list ? _c('Spinner') : _c('div', [_c('FormGroup', {
    attrs: {
      "id": "assetId",
      "error": _vm.validationErrors['assetId'],
      "options": _vm.filteredAssets,
      "type": "select2",
      "config": {
        allowSearch: true
      },
      "label": "Asset"
    },
    on: {
      "input": _vm.onSelectAsset
    },
    model: {
      value: _vm.form.assetId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "assetId", $$v);
      },
      expression: "form.assetId"
    }
  })], 1)], 1)])], 1), _vm.form.assetId ? _c('div', {
    staticClass: "tab-pane",
    class: {
      active: _vm.step === 2
    }
  }, [_vm.error.message ? _c('div', {
    staticClass: "alert alert-danger",
    attrs: {
      "role": "alert"
    }
  }, [_c('h3', {
    staticClass: "alert-heading font-size-h4 my-2"
  }, [_vm._v("Error")]), _c('div', {
    staticClass: "mb-0"
  }, [_c('strong', [_vm._v(_vm._s(_vm.error.message))]), _vm._l(_vm.validationErrors, function (error, key) {
    return _c('div', {
      key: key
    }, [_vm._v(_vm._s(error))]);
  })], 2)]) : _vm._e(), _c('AccountForm', {
    attrs: {
      "account-id": _vm.accountId,
      "error": _vm.validationErrors,
      "country-code": "gb",
      "is-create": true,
      "asset-id": _vm.form.assetId
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "tab-pane",
    class: {
      active: _vm.step === 3
    }
  }, [_c('SectionTitle', [_vm._v("Confirmation")]), _vm.loading.submit ? _c('div', {
    staticClass: "d-flex justify-content-center align-items-center flex-column py-5"
  }, [_c('div', {
    staticClass: "spinner-border spinner-lg text-secondary my-5",
    attrs: {
      "role": "status"
    }
  }), _c('p', [_vm._v("Please wait while the Account is created...")])]) : _c('div', {
    staticClass: "text-center"
  }, [_vm.accountCreated && !_vm.error.message ? _c('p', {
    staticClass: "text-muted font-w600"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-check-circle text-success"
  }), _vm._v(" The account was created successfully. "), _c('router-link', {
    attrs: {
      "to": {
        name: 'asset-accounts-overview',
        params: {
          id: _vm.form.assetId,
          accountId: _vm.createdAccount._id
        }
      },
      "data-cy": "account-dashboard-btn"
    }
  }, [_vm._v("Click here")]), _vm._v(" to go to the account. ")], 1) : _vm._e(), _vm.error.message ? _c('p', [_vm._v("There was an error creating this account. Go back to Step 2.")]) : _vm._e()])], 1)]), _c('div', {
    staticClass: "block-content block-content-sm block-content-full bg-body-light rounded-bottom"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_vm.step === 2 ? _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button",
      "data-wizard": "prev"
    },
    on: {
      "click": _vm.onClickPrevStep
    }
  }, [_c('i', {
    staticClass: "fa fa-angle-left mr-1"
  }), _vm._v(" Previous ")]) : _vm._e()]), _c('div', {
    staticClass: "col-6 text-right"
  }, [_vm.step === 1 ? _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "data-cy": "next-btn",
      "type": "button"
    },
    on: {
      "click": _vm.onClickNextStep
    }
  }, [_vm._v(" Next "), _c('i', {
    staticClass: "fa fa-angle-right ml-1"
  })]) : _vm._e(), _vm.step === 2 ? _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "data-cy": "create-btn",
      "type": "submit"
    },
    on: {
      "click": _vm.onClickSubmit
    }
  }, [_c('i', {
    staticClass: "fa fa-check-circle mr-1"
  }), _vm._v(" Create Account ")]) : _vm._e(), _vm.step === 3 && _vm.accountCreated ? _c('button', {
    staticClass: "btn btn-success",
    attrs: {
      "data-cy": "create-another-btn"
    },
    on: {
      "click": _vm.onCreateAnother
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-plus-circle mr-1"
  }), _vm._v("Create another ")]) : _vm._e()])])])])])], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-4"
  }, [_c('p', {
    staticClass: "text-muted"
  })]);

}]

export { render, staticRenderFns }